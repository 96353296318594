<template>
    <div>
        <h2><router-link :to="{ name: 'saleorder' }">รายการขาย</router-link> > ยืนยันรายการ {{ order.orderNumber }}</h2>
        <v-row class="mt-4">
          <v-col>
            <v-card>
              <v-form v-model="isFormValid">
                <v-container fluid>
                  <v-row>
                    <v-col>
                      <product-input
                      v-model="order"
                      :url="'saleorder'"
                      :disabled="true"
                      ></product-input>
                    </v-col>              
                  </v-row>
                  <v-row no-gutters>
                    <v-col>
                      <v-divider></v-divider>
                    </v-col>              
                  </v-row>
                  <v-row v-if="order.paymentInfo">
                    <v-col>
                        ช่องทางการจ่ายเงิน : {{ order.paymentInfo.info.bank.name }} ( {{ order.paymentInfo.info.accountNo }} )
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>หลักฐานการชำระ</v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                        <light-box 
                        :src="order.slipImg"
                        max-width="300"
                        max-height="300"
                        ></light-box>                        
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>

              <v-card-actions class="justify-end">                
                <v-btn
                color="secondary"               
                text                 
                large
                :disabled="sendingData"
                @click="$router.push({ name: 'saleorder' })"
                >
                กลับ
                </v-btn>
                <v-btn
                color="secondary"               
                outlined                 
                large
                :loading="sendingData"
                @click="reject"
                >
                ปฏิเสธ
                </v-btn>
                <v-btn
                color="primary"               
                large          
                :disabled="!isFormValid"
                :loading="sendingData"
                @click="confirm"
                >
                ยืนยัน
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
    </div>
</template>
<script>
import { Order } from '@/classes/order/Order'
import DelayCombobox from '@/components/DelayCombobox.vue'
import ProductInput from '@/components/order/ProductInput.vue'
import { asyncGet, asyncPostAsJson } from '@/helpers/asyncAxios'
import { minValueValidator, numberValidator, required } from '@core/utils/validation'
export default {
  data() {
    return {
      url: process.env.VUE_APP_ROOT_API,
      orderNumber: null,
      isFormValid: false,
      sendingData: false,
      order: null,
      image: null,
      formData: {
        payment_id: null,
        amount_transferred: '',
      },
      validators: {
        required,
        numberValidator,
        minValueValidator,
      },
    }
  },
  async created() {
    const saleorder_id = this.$route.params.saleorder_id

    this.order = new Order({})

    this.$store.commit('app/SET_GLOBAL_LOADING', true)
    try {
      const response = await asyncGet('/saleorder/' + saleorder_id)

      this.convertDataFromServerToForm(response)
    } catch (error) {
      this.$root.showCommonDialog('มีปัญหา', error)
    }
    this.$store.commit('app/SET_GLOBAL_LOADING', false)
  },
  methods: {
    async reject() {
      this.sendingData = true
      try {
        const saleorder_id = this.$route.params.saleorder_id
        const retResult = await asyncPostAsJson('/saleorder/' + saleorder_id + '/reject', {})

        this.$router.push({ name: 'saleorder' })
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', error)
      }

      this.sendingData = false

      this.$router.push({ name: 'saleorder' })
    },
    async confirm() {
      this.sendingData = true
      try {
        const saleorder_id = this.$route.params.saleorder_id
        const retResult = await asyncPostAsJson('/saleorder/' + saleorder_id + '/confirm', {})

        this.$router.push({ name: 'saleorder' })
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', error)
      }

      this.sendingData = false

      this.$router.push({ name: 'saleorder' })
    },
    convertDataFromServerToForm(orderDataFromServer) {
      this.order = new Order(orderDataFromServer)

      const { products, warehouse } = orderDataFromServer

      this.warehouse = warehouse

      /*
      const order = this.order

      products.forEach(element => {
        const orderItem = new OrderItem(
          element.id,
          element.images && !!element.images[0] ? element.images[0].URI : null,
          element.name,
          element.sku,
          element.barCode,
          element.pivot.note,
          element.pivot.quantity,
          element.pivot.price,
          element.pivot.discount,
          element.pivot.discount_type,
          element.pivot.vat,
        )

        order.addOrderItem(orderItem)
      })
      */
    },
  },
  components: {
    ProductInput,
    DelayCombobox,
  },
}
</script>,